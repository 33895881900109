import { SummaryCTA } from '@dmm/lib-react-ui-components';
import { AdProvider } from '@dmm/react-common-components';
import loadable from '@loadable/component';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../../components/BreadCrumb';
import ImmersiveTourModal from '../../../components/ImmersiveTourModal';
import NextPreviousDataProvider from '../../../components/NextPreviousDataProvider';
import ScrollManager from '../../../components/ScrollManager';
import Sticky from '../../../components/Sticky';
import { withABTest } from '../../../server/abTest/withABTest';
import { likeBoat, unlikeBoat } from '../../../store/actions';
import { addLead, addSaveBoat } from '../../../store/actions/dataLayer';
import { BRANCHIO_CONTENT_TYPES, BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { detailsMapper } from '../../../utils/infoBoxButtonHelper';
import {
  getLocation, getPrePopulatedText, getPrice,
  getPriceCut,
  getTitle, isFSBOContact,
  roundLength, addResizeParams
} from '../../../utils/listingHelper';
import * as storage from '../../../utils/storage';
import { checkPreQualVariation, isFinanceable, isSegmentCandidateForFinance, makeFinanceableSummaryTrackingEvent } from '../../../utils/trident';
import BDPAd from '../BDPSections/BDPAd';
import BDPAlert from '../BDPSections/BDPAlert';
import BDPBoatSummary from '../BDPSections/BDPBoatSummary';
import BDPCarouselModal from '../BDPSections/BDPCarouselModal';
import { BDPDealerLocator, BDPDealerLocatorForm } from '../BDPSections/BDPDealerLocator';
import BDPDesktopAds from '../BDPSections/BDPDesktopAds';
import BDPDetailsSection from '../BDPSections/BDPDetailsSection';
import BDPEmailLeadForm from '../BDPSections/BDPEmailLeadForm';
import BDPGetPreQualifiedModal from '../BDPSections/BDPGetPreQualifiedModal';
import BDPHelmet from '../BDPSections/BDPHelmet';
import BDPScrollAnchor from '../BDPSections/BDPScrollAnchor';
import DetailsContact from '../DetailsContact';
import FinanceableBoatSummary from '../FinanceableBoatSummary';
import NextPrevious from '../NextPrevious';
import { parseDetailsSectionState } from './bdpController/bdpHelpers';
import { useBdp } from './bdpController/bdpManager';
import { adsConfig, getTargeting } from './resources/adsConfig';
import './styles.css';
import { BoatDescription } from '../FinanceableBoatSummary/BoatDescription';
import * as utils from '../../../store/utils';
import { BOAT_DETAIL_CTA_LABEL } from '../../../constants/boatDetails';
import ServerAd from '../../../components/ServerAd';
const AdditionalLeadsModal = loadable(() => import('../../../components/AdditionalLeadsModal'));


const BDPSeller = (props) => {
  const {state, bdp} = useBdp(props);
  const {
    breakpoint,
    showModalDealerLocator,
    showModalImmersiveTour,
    showModalCarousel,
    carouselIndex,
    openContactForm,
    showModalPreQualified,
    setListingViewSent
  } = state;

  const showModalPostLead = useSelector((state) => get(state, 'app.additionalLeads.open', false));

  const {bdpContext, bdpHandlers, carouselButtons} = bdp;
  const {
    url, mediaImages, breadCrumbItems,
    listingTitle, virtualTourUrl, locationPath
  } = bdpContext;

  const {
    ads,
    tridentTermInMonths,
    tridentLoanAmount,
    tridentTeaserRate,
    isWorking,
    listing,
    cookies,
    myboats = [],
    carouselClickCount,
    abTestConfiguration,
    noAdsParam,
    userEmail
  } = props;

  const {
    summaryHandlers, hideModals, handleOpenContactForm, openCarousel,
    closeImmersiveTourModal, openImmersiveTourModal, openModalPostLead, previousNextHandler,
    openModalDealerLocator, handleCloseContactForm, displayModalPreQualified,
    handleTrackOpenContactForm, setProductData, setListingViewData, setGenericEvent
  } = bdpHandlers;

  const serverAdsHash = ads?.reduce((acc, obj) => {
    acc[obj.key] = obj.value;
    return acc;
  }, {});

  const {oemDetails} = listing;
  const showMoreBoats = !oemDetails;
  const showDealers = oemDetails && oemDetails.oemDealerLocator;
  const showBoatSummary = !showModalCarousel;

  let targeting = getTargeting(props.listing, Math.floor(carouselClickCount / 3).toString());

  const [stackedGalleryScroll, setStackedGalleryScroll] = useState(props.initialScroll || 0);
  const [stickyContactFade, setStickyContactFade] = useState(false);

  useEffect(() => {
    if (stackedGalleryScroll > 150) {
      setStickyContactFade(true);
    } else {
      setStickyContactFade(false);
    }
  }, [stackedGalleryScroll]);

  useEffect(() => {
    setProductData(listing);
    targeting = getTargeting(props.listing, Math.floor(carouselClickCount / 3).toString()); // eslint-disable-line react-hooks/exhaustive-deps
    setListingViewData(listing, setListingViewSent);
  }, [setProductData(listing), setListingViewData(listing, setListingViewSent)]);

  const reloadAdOverride = Math.floor(carouselClickCount / 3) || 0;
  const reloadAdMobile = Math.floor(carouselClickCount / 3) || 0;

  const {
    ['boat-details_leaderboard-top']: LeaderboardTopDesktop,
    ['boat-details_leaderboard-bottom']: LeaderboardBottomDesktop,
    ['boat-details_mobile-leaderboard-1']: LeaderboardTopMobile,
    ['boat-details_mobile-box-2']: BoxTwoMobile
  } = serverAdsHash || {};

  const adButlerMapping = {
    desktop: {
      LeaderboardTop: [LeaderboardTopDesktop, 'ad-leaderboard-top'],
      BottomAd: [LeaderboardBottomDesktop, 'ad w728'],
    },
    mobile: {
      LeaderboardTop: [LeaderboardTopMobile, 'mobile-ad-leaderboard-top'],
      BottomAd: [BoxTwoMobile, 'ad'],
    },
  };
  const normalizedBreakpoint = breakpoint === 'tablet' ? 'mobile' : breakpoint; //for ads since tablet doesnt exits

  const [leaderboardTopAd, leaderboardTopClass] = adButlerMapping[normalizedBreakpoint]?.LeaderboardTop || [];
  const [bottomAd, bottomClass] = adButlerMapping[normalizedBreakpoint]?.BottomAd || [];



  const sideBarAd1Params = {
    adId: 'div-gpt-box-1',
    classes: 'right-rail-ad ad-300 box-holder',
    reloadAd: reloadAdOverride
  };
  const sideBarAd2Params = {
    adId: 'div-gpt-box-2',
    classes: 'right-rail-ad ad-300 box-holder',
  };
  const sideBarAd3Params = {
    adId: 'div-gpt-box-3',
    classes: 'right-rail-ad ad-300 box-holder',
  };
  const bdpDesktopAds = {sideBarAd1Params, sideBarAd2Params, sideBarAd3Params};
  const leaderboardTopParams = {
    adId: 'div-gpt-leaderboard-top',
    classes: 'ad-leaderboard-top',
    reloadAd: reloadAdOverride
  };
  const leaderboardBottomParams = {
    adId: 'div-gpt-leaderboard-bottom',
    classes: 'ad w728',
  };
  const mobileBox1Params = {
    adId: 'div-gpt-mobile-box-1',
    classes: 'ad',
  };
  const mobileBox2Params = {
    adId: 'div-gpt-mobile-box-2',
    classes: 'ad',
  };
  const mobileLeaderboardParams = {
    adId: 'div-gpt-mobile-leaderboard-1',
    classes: 'mobile-ad-leaderboard-top',
    reloadAd: reloadAdMobile
  };

  // Variant FINANCE 2
  const shouldShowFinanceSummary = isSegmentCandidateForFinance(listing);
  const isPrivateSeller = isFSBOContact(listing.contact);
  const buyerAccountLoginActive = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const showDetailsContactModal = !buyerAccountLoginActive || userEmail;
  const price = get(listing, 'price.type.amount.USD');
  const financeable = isFinanceable(price, listing?.year);

  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const { featureFlagBDPPhase4, useServerAds } = useFeatureFlags();
  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const userEmailAvailable = userEmail !== undefined;
  const isPrivateSellerConnect = userEmailAvailable && isUserLoginExperiment;

  const [liked, setLiked] = useState(null);
  const [hasScrolledPast, setHasScrolledPast] = useState(false);
  const [disableRequestInfo, setDisableRequestInfo] = useState(false);

  const { fireBranchioMetaTag } = useBranchIoMetaTag();
  const getQueuedBoatsFromLocalStorage = () => JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];
  const listingId = listing.aliases ? listing.aliases['boat-trader'] : undefined;
  const boatLength = `| ${roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'))}'`;
  const src = listing?.media?.find(obj => obj.mediaType === 'image')?.url;
  const dateModified = listing?.media?.find(obj => obj.mediaType === 'image')?.date.modified;
  const summaryProps = {
    title: getTitle(listing, true),
    price: getPrice(listing),
    priceDrop: getPriceCut(listing) || '',
    location: getLocation(listing),
    boatLengthL: boatLength,
    phoneNumber: listing.contact?.phone || ''
  };
  const showSummaryCTA = breakpoint !== 'mobile' && hasScrolledPast && featureFlagBDPPhase4 && !isPrivateSellerConnect;

  useEffect(() => {
    const handleScroll = () => {

      const current = targetRef?.current;
      const containerOffset = 50;
      const targetPosition = current?.offsetTop + current?.offsetHeight - containerOffset;
      const scrollPosition = window?.scrollY;

      if (targetPosition <= scrollPosition) {
        setHasScrolledPast(true);
      } else {
        setHasScrolledPast(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => updateLikedState(), [myboats]);

  const updateLikedState = ()=> setLiked(getLikedState());

  const getLikedState = ()=> {
    if (utils.isServer()) {
      return false;
    }
    if (myboats.requireLogin) {
      const queuedBoats = getQueuedBoatsFromLocalStorage();
      return queuedBoats.includes(listingId);
    }

    return myboats.listings
      ? (myboats.listings.filter(
        (boat) => { return parseInt(boat.listingId) === parseInt(listingId);}).length > 0
      )
      : false;
  };

  const queueLikedBoat = (listingId) => {
    const queuedBoats = getQueuedBoatsFromLocalStorage();
    if (!queuedBoats.includes(listingId)) {
      queuedBoats.push(listingId);
      setLiked(true);
    } else {
      setLiked(false);
      queuedBoats.splice(queuedBoats.indexOf(listingId), 1);
    }
    localStorage.setItem('QUEUED_LIKED_BOATS', JSON.stringify(queuedBoats));
  };

  const forwardToLogin = () => {
    props.userRef.current.onOpenModal();
  };

  const addBoatToMyBoats = (listingId) => {
    let data = {listingId: listingId};
    dispatch(likeBoat(data));
    branchIoEventsManager(BRANCH_EVENT.LIKE_BOAT, {
      sku: get(listing, 'id', ''),
      'product_name': get(listing, 'model', ''),
      'product_brand': get(listing, 'validMake', ''),
    });
  };

  const removeBoatFromMyBoats = (listingId) => {
    dispatch(unlikeBoat(listingId));
  };

  const trackPreQualLinkClick = (actionLabel) =>{
    const preQualTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(preQualTracking);
  };

  const onClickLike = () => {
    const imtId = listing.aliases?.imt;
    if (!liked) {
      fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
    }
    if (myboats.requireLogin) {
      queueLikedBoat(listingId);
      forwardToLogin();
    } else {
      if (!liked) {
        setLiked(true);
        addBoatToMyBoats(listingId);
        dispatch(addLead(imtId, 'save boat'));
        addSaveBoat();
      } else {
        setLiked(false);
        removeBoatFromMyBoats(listingId);
      }
    }
    return false;
  };

  const onClickPreQualified = () => {
    trackPreQualLinkClick('pre-qualify & get started');
    checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
  };

  const onClickRequestInfo = (e, UIEvent) => {
    handleOpenContactForm(e, UIEvent);
  };

  return (
    <>
      {/* Hidden label for accessibility purposes */}
      <label htmlFor={'g-recaptcha-response-100000'} style={{ display: 'none' }} data-testid={'g-rc-label-100000'}>
        reCAPTCHA
      </label>
      <label htmlFor={'g-recaptcha-response-100001'} style={{ display: 'none' }} data-testid={'g-rc-label-100001'}>
        reCAPTCHA
      </label>
      <BDPDealerLocator
        device={breakpoint}
        hideModal={hideModals}
        isOpen={showModalDealerLocator}
        origin="BDP"
        isWorking={isWorking}
      />
      <ImmersiveTourModal isOpen={showModalImmersiveTour}
        closeModal={closeImmersiveTourModal}
        title={listingTitle}
        url={virtualTourUrl}
      />
      <BDPHelmet listing={listing} breadCrumbItems={breadCrumbItems} mediaImages={mediaImages} />
      <AdProvider url={locationPath} isWorking={isWorking} targeting={targeting} adsConfig={adsConfig} noAdsParam={noAdsParam}>
        <div className="top-ad">
          {(useServerAds && serverAdsHash) ?  <ServerAd ad={leaderboardTopAd} className={leaderboardTopClass}/> :
            <BDPAd
              show={true}
              addParams={breakpoint === 'desktop' ? leaderboardTopParams : mobileLeaderboardParams}/>
          }
        </div>
        <ScrollManager />
        <SummaryCTA
          className={`summary-cta ${showSummaryCTA ? 'show-summary-cta' : ''}`}
          src={src && addResizeParams(src, {width: 124, height: 83}, dateModified)}
          summary={summaryProps}
          liked={liked}
          onClickLike={onClickLike}
          onClickPreQualified={onClickPreQualified}
          onClickRequestInfo={(e) => onClickRequestInfo(e, { dataTestingClass: { suffix: 'sticky-nav-modal' }})}
          hidePreQualifiedButton={!financeable}
          contactBtnText={BOAT_DETAIL_CTA_LABEL}
          buttonAttributes={{ 'data-testing-class': `contact-seller-${breakpoint}-sticky-nav` }}
        />
        <NextPreviousDataProvider {...props} storage={storage} />
        <div className="broker-dealer-next-previous">
          <NextPrevious isLoading={isWorking} listing={listing} myboats={myboats} cookies={cookies}
            breadCrumbs={breadCrumbItems} url={url} navLinks={props.navLinks}
            showInfo={state.scrolledPastBoatSummary} breakpoint={breakpoint}
            tracking={{ setGenericEvent: setGenericEvent }}
            showMoreBoats={showMoreBoats} liked={liked} onClickLike={onClickLike}
          />
          <div className="boat-details boat-details-gradient">
            <BDPScrollAnchor />
            <div className="top broker-bdp">
              <div className="broker-bdp-left">
                <BDPCarouselModal
                  breakpoint={breakpoint}
                  isLoading={isWorking}
                  showMoreBoats={showMoreBoats}
                  showThumbnails={true}
                  show={showModalCarousel}
                  carouselIndex={carouselIndex}
                  hideModal={hideModals}
                  carouselButtons={carouselButtons}
                  handleOpenContactForm={handleOpenContactForm}
                  openImmersiveTourModal={openImmersiveTourModal}
                  handleCarouselItemClick={(e, index) => openCarousel(index)}
                  previousNextHandler={previousNextHandler}
                  liked={liked}
                  onClickLike={onClickLike}
                  setStackedGalleryScroll={setStackedGalleryScroll}
                />
                {breakpoint !== 'desktop' &&
                  <>
                    <BreadCrumb items={breadCrumbItems.slice(0, -1)} dataTestId="breadcrumb-component-mobile"/>
                    {!shouldShowFinanceSummary && !isPrivateSeller &&
                      <BDPBoatSummary
                        show={showBoatSummary}
                        isWorking={isWorking}
                        breakpoint={breakpoint}
                        summaryHandlers={summaryHandlers}
                        openModalDealerLocator={openModalDealerLocator}
                        openModal={displayModalPreQualified}
                      />
                    }
                    {(shouldShowFinanceSummary || isPrivateSeller) &&
                      <BoatDescription
                        listing={listing}
                        tridentTeaserRate={tridentTeaserRate}
                        tridentTermInMonths={tridentTermInMonths}
                        tridentLoanAmount={tridentLoanAmount}
                      />
                    }
                  </>
                }
                <div className="content">
                  <Sticky position="top" offset={51} onChange={summaryHandlers.handleBoatSummaryScroll}/>
                  {showDetailsContactModal && (
                    <DetailsContact
                      isLoading={isWorking}
                      listing={listing}
                      externalOpen={openContactForm}
                      prePopulatedText={getPrePopulatedText(listing, state?.UIEvent?.type)}
                      formTitle={get(detailsMapper, `${state?.UIEvent?.type}.title`)}
                      isInfoButton={get(state, 'UIEvent.eventName') === 'infobox'}
                      closeButtonFunc={handleCloseContactForm}
                      showModalDealerLocator={openModalDealerLocator}
                      useRecaptcha={true}
                      showAdditionalLeads={true}
                      inModal={true}
                      dataTestingClass={state?.UIEvent?.dataTestingClass}
                      disableSticky={breakpoint === 'desktop'}
                      hideButtons={shouldShowFinanceSummary}
                      userEmail={userEmail}
                      fadeButtons={stickyContactFade}
                    />
                  )}
                  <BDPDetailsSection
                    isWorking={isWorking}
                    myboats={myboats}
                    cookies={cookies}
                    url={url}
                    breakpoint={breakpoint}
                    listing={listing}
                    detailsState={parseDetailsSectionState(state)}
                    adParams={{mobileBox1Params, mobileBox2Params}}
                    displayModalPreQualified={displayModalPreQualified}
                    onClickRequestInformation={showDealers ? openModalDealerLocator : handleTrackOpenContactForm}
                    shouldShowFinanceSummary={shouldShowFinanceSummary}
                    userRef={props.userRef}
                    userEmail={props.userEmail}
                    openModalPostLead={openModalPostLead}
                    setDisableRequestInfo={setDisableRequestInfo}
                    serverAds={serverAdsHash}
                    useServerAds={useServerAds}
                  />
                  <div className="bottom-ad">
                    {(useServerAds && serverAdsHash && breakpoint === 'desktop') ?  <ServerAd ad={bottomAd} className={bottomClass}/> :
                      <BDPAd
                        show={breakpoint === 'desktop'}
                        addParams={leaderboardBottomParams}
                        noAdsParam={noAdsParam}/>
                    }
                    {(useServerAds && serverAdsHash && breakpoint === 'mobile') ?  <ServerAd ad={bottomAd} className={bottomClass}/> :
                      <BDPAd
                        show={breakpoint === 'mobile'}
                        addParams={mobileBox2Params}
                        noAdsParam={noAdsParam}/>
                    }
                  </div>
                </div>
              </div>
              <div className="top-right broker-bdp-right">
                {breakpoint === 'desktop' &&
                <div className="boat-summary-ref" ref={targetRef}>
                  {(!shouldShowFinanceSummary && !isPrivateSeller) &&
                    <BDPBoatSummary
                      show={showBoatSummary}
                      isWorking={isWorking}
                      breakpoint={breakpoint}
                      summaryHandlers={summaryHandlers}
                      openModalDealerLocator={openModalDealerLocator}
                      openModal={displayModalPreQualified}
                      showLoansLink={false}
                      hideForm={shouldShowFinanceSummary}
                    />
                  }
                  {(shouldShowFinanceSummary || isPrivateSeller) &&
                    <FinanceableBoatSummary
                      listing={listing}
                      tridentTermInMonths={tridentTermInMonths}
                      tridentLoanAmount={tridentLoanAmount}
                      tridentTeaserRate={tridentTeaserRate}
                      isMobile={breakpoint !== 'desktop'}
                      openModalPostLead={openModalPostLead}
                      disableRequestInfo={disableRequestInfo}
                      userRef={props.userRef}
                      userEmail={props.userEmail}
                      breakpoint={breakpoint}
                    />
                  }
                </div>
                }
                <BDPEmailLeadForm
                  listing={listing}
                  breakpoint={breakpoint}
                  onSuccess={openModalPostLead}
                  hideForm={shouldShowFinanceSummary || isPrivateSeller}
                  buttonAttributes={{ 'data-testing-class': `form-submission-${breakpoint}-seller` }}
                />
                <BDPDesktopAds
                  addParams={bdpDesktopAds}
                  show={breakpoint === 'desktop'}
                  noAdsParam={noAdsParam}
                  serverAds={serverAdsHash}
                  useServerAds={useServerAds}
                />
              </div>
              <BDPDealerLocatorForm openModalDealerLocator={openModalDealerLocator}/>
            </div>
          </div>
        </div>
        {showModalPostLead && <AdditionalLeadsModal/>}
        <BDPGetPreQualifiedModal
          isMobile={breakpoint === 'mobile'}
          preQualifiedState={{ showModalPreQualified }}
          onClose={displayModalPreQualified}
        />
        <BDPAlert />
      </AdProvider>
    </>
  );
};

export default withABTest(BDPSeller);

import React, { useEffect, useState } from 'react';
import {generateSearchPath} from '../../../utils/urlHelpers/listings';
import { DarkCard } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import { ENGINES_ID } from '../../../constants/Engines';
import  { scrollTo } from '../../../utils/scrollTo';
import { Helmet } from 'react-helmet';

import './styles.css';
import AdProvider from '../../../components/Ads/AdProvider';
import { boatTypeAddProps } from '../resources/boatTypeAds';

import BoatTypeSection from '../BoatTypeSection/BoatTypeSection';
import {BoatTypeAdd} from '../BoatTypeSection/BoatTypeAdd';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGenericEvent } from '../../../store/actions/dataLayer';

const MainContent = ({records, setGenericEvent}) => {
  const [sticky, setSticky] = useState(false);
  const [showStickyMenu, setShowStickyMenu] = useState(true);
  const whenScroll = () => {
    setSticky(!!document.querySelector('.show-header'));
  };

  const showSticky = (show) => {
    setShowStickyMenu(show);
  };

  useEffect(() => {
    window.addEventListener('scroll', whenScroll);
    return () => {
      window.removeEventListener('scroll', whenScroll);
    };
  });

  const staticUrlsMap = {
    'trailers': '/browse/trailers/',
    'engines': generateSearchPath({},{}, true, ENGINES_ID)
  };

  const handleClick = (ev, id, eventLabel) => {
    ev.preventDefault();
    document.querySelectorAll('.type-link').forEach((el) => {
      el.classList.remove('active');
    });
    ev.target.classList.add('active');
    setGenericEvent('boats category menu', 'click', eventLabel, '', 'detailed_page_name,Boat Types');
    scrollTo(id, { behavior: 'smooth' });
  };

  const mapDarkCardProps = (item) => {
    return {
      title: item.title,
      subtitle: item.description,
      url: `${process.env.REACT_APP_HOST}${staticUrlsMap[item.typeId]}`,
      button: `Explore ${item.typeId}`,
      image: `${process.env.REACT_APP_HOST}/static/boat-types/${item.typeId}.jpg`
    };
  };

  const leftScroll = () => {
    const left = document.querySelector('.boat-type-overflow');
    left?.scrollBy(-200, 0);
  };

  const rightScroll = () => {
    const right = document.querySelector('.boat-type-overflow');
    right?.scrollBy(200, 0);
  };

  if (!Array.isArray(records)) {
    return null;
  }

  const arrayBoatTypes = records.filter( obj => !['trailers', 'engines'].includes(obj.typeId));
  const arraySpecialBoatTypes = records.filter( obj => ['Trailers', 'Engines'].includes(obj.title));
  const canonical = `${process.env.REACT_APP_HOST}/boat-types/`;
  const adProviderProps = boatTypeAddProps(canonical);
  const boatTypeClass = 'boattypes-ads';
  const stickyClass = sticky ? 'boat-type-links-mobile boat-type-links-mobile-top' : 'boat-type-links-mobile';

  return (
    <>
      <Helmet>
        <title>Choosing the Right Type of Boat - Boat Trader</title>
        <meta name="og:title" content="Choosing the Right Type of Boat - Boat Trader" />
        <meta name="description"
          content="There are many different types of boats available and looking at a large list of boats for sale can be daunting. Find the right boat for you!"
        />
        <meta name="og:description"
          content="There are many different types of boats available and looking at a large list of boats for sale can be daunting. Find the right boat for you!"
        />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <AdProvider {...adProviderProps}>
        <div className="boat-types-container">
          <BoatTypeAdd id={'div-gpt-leaderboard-top'} classes={boatTypeClass}/>
          <div className="all-boat-types boat-type-content">
            <div className="all-type-links">
              <h1>Choosing the Right Type of Boat</h1>
              <div className="boat-type-links">
                <div className="boat-type-links-width">
                  <a className="type-link" data-e2e="power-boats-type-link" onClick={(ev)=> {handleClick(ev, '#power-boats', 'Power Boats');}}>Power Boats</a>
                  <a className="type-link" data-e2e="sail-boats-type-link" onClick={(ev)=> {handleClick(ev, '#sailboats', 'Sailboats');}}>Sailboats</a>
                  <a className="type-link" data-e2e="personal-watercraft-type-link" onClick={(ev)=> {handleClick(ev, '#pwc-and-jet-ski', 'Personal Watercraft');}}>Personal Watercraft</a>
                  <a className="type-link" data-e2e="small-boats-type-link" onClick={(ev)=> {handleClick(ev, '#small-boats', 'Small Boats');}}>Small Boats</a>
                </div>
              </div>
            </div>
            <p className="intro-text-font">Before you buy a boat, it is beneficial to familiarize yourself with the most fundamental aspects of boat construction,
              including common <a href="/research/boat-types-and-hulls-guide/" data-e2e="boat-hull-types-link">boat hull types</a>, layouts and designs as well as
              the best boat brands on the market. Our <a href="/digital/boating-lifestyle-calculator/" data-e2e="boat-life-style-calculator-link">boating lifestyle calculator</a> can
              assist in filtering the many different types of boats available from our large list of <a href="/boats/" data-e2e="boats-for-sale-link">boats for sale</a> according
              to your income level, budget and intended boating activities.
            </p>
          </div>
          {showStickyMenu && <div className={stickyClass}>
            <span className="left" onClick={leftScroll}>&lt;</span>
            <div className="boat-type-overflow">
              <div className="boat-type-links-width-mobile">
                <a className="type-link" onClick={(ev) => {
                  handleClick(ev, '#power-boats', 'Power Boats');
                }}>Power Boats</a>
                <a className="type-link" onClick={(ev) => {
                  handleClick(ev, '#sailboats', 'Sailboats');
                }}>Sailboats</a>
                <a className="type-link" onClick={(ev) => {
                  handleClick(ev, '#pwc-and-jet-ski', 'Personal Watercraft');
                }}>Personal Watercraft</a>
                <a className="type-link type-link-last" onClick={(ev) => {
                  handleClick(ev, '#small-boats', 'Small Boats');
                }}>Small Boats</a>
              </div>
            </div>
            <span className="right" onClick={rightScroll}>&gt;</span>
          </div>}

          {
            arrayBoatTypes.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <BoatTypeSection item={item} key={item.typeId} index={index} setGenericEvent={setGenericEvent} showSticky={showSticky} />
                  { index === 0 &&
                    <>
                      <BoatTypeAdd id={'div-gpt-leaderboard-mid'} classes={boatTypeClass}/>
                      <BoatTypeAdd id={'div-gpt-mobile-box-1'} classes={boatTypeClass}/>
                    </>
                  }
                  { index === 1 && <BoatTypeAdd id={'div-gpt-mobile-box-2'} classes={boatTypeClass}/> }
                </React.Fragment>
              );
            })
          }
          <div className={classnames('specialTypeSection', 'boat-type-content')} date-e2e="special-type-section">
            { arraySpecialBoatTypes.map((item) => (
              <div key={item.typeId}>
                <DarkCard {...mapDarkCardProps(item)} />
              </div>
            ))}
          </div>
        </div>
        <>
          <BoatTypeAdd id={'div-gpt-leaderboard-bottom'} classes={boatTypeClass}/>
          <BoatTypeAdd id={'div-gpt-mobile-box-3'} classes={boatTypeClass}/>
        </>
      </AdProvider>
    </>
  );
};

MainContent.propTypes = {
  records: PropTypes.any,
  key: PropTypes.string
};

export default connect(
  undefined,
  (dispatch) => bindActionCreators({ setGenericEvent }, dispatch)
)(MainContent);

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import AdProvider from '../../../components/Ads/AdProvider';
import { BOATS_ID } from '../../../constants/home';
import * as utils from '../../../store/utils';
import { getDesktopMobileBreakpoint } from '../../../utils/commonHelper';
import { getOrganizationSchema, getWebsiteSchema } from '../../../utils/metaContentHelper';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import ArticlesAndReviews from '../ArticlesAndReviews';
import BoatsNearYou from '../BoatsNearYou';
import FSBOBanner from '../FSBOBanner';
import HeroAd from '../HeroAd';
import HeroServerAd from '../HeroServerAd';
import PopularBoats from '../PopularBoats';
import SearchSubmission from '../SearchSubmission';
import { getAdsConfig } from './resources/adsConfig';
import CompanionServerAd from '../CompanionServerAd';
import { matchAdName } from '../../../utils/ads/adsUtils';
import './styles.css';

const getHeroBreakpoint = () => {
  if (!utils.isServer()){
    return getDesktopMobileBreakpoint();
  }
};

const MainContent = (props) => {
  const articlesAndReviews = get(props, 'home.articlesAndReviews', []);
  const popularBoats = get(props, 'home.popularBoats', []);
  const isWorking = useSelector((state) => state.app.isWorking);
  const noAdsParam = get(queryString.parse(props.location.search), 'noAds', null) === 'true';
  const zipcode = get(props, 'home.zipcode', '');
  const radiusMiles = get(props, 'home.radiusMiles', 200);
  const coordinates = get(props, 'home.coordinates', []);
  const serverAds = props?.home?.ads;
  const heroServerAd = matchAdName(serverAds, 'home_hero');
  const homeCompanion = matchAdName(serverAds, 'home_companion');
  const homeCompantionStick = matchAdName(serverAds, 'home_companion-sticky-banner');

  const { useServerAds } = useFeatureFlags();
  const adsConfig = getAdsConfig();

  const websiteSchema = <script type="application/ld+json">{JSON.stringify(getWebsiteSchema())}</script>;
  const organizationSchema = <script type="application/ld+json">{JSON.stringify(getOrganizationSchema())}</script>;

  return (
    <>
      <Helmet>
        {websiteSchema}
        {organizationSchema}
      </Helmet>
      <AdProvider
        url={props.location.pathname}
        isWorking={isWorking}
        targeting={{ page: 'home' }}
        adsConfig={adsConfig}
        noAdsParam={noAdsParam}
        retargetOnAdRefresh={true}
      >
        <span className="nav-cover"></span>
        <div id="homepage">
          <div className="bt-inc-release">
            <section className="main-content home">
              <aside className="home-search">
                <SearchSubmission zipcode={zipcode} defaultTab={BOATS_ID} radiusMiles={radiusMiles}/>
                <FSBOBanner hasImage={false} />
              </aside>
              { useServerAds && !isEmpty(heroServerAd) && <HeroServerAd ad={heroServerAd} />}
              { !useServerAds && <HeroAd breakpoint={ getHeroBreakpoint() } />}
            </section>

            <section id="recommended-boats" data-e2e="recommended-boats">
              <BoatsNearYou cookies={props.cookies} coordinates={coordinates}/>
            </section>

            { useServerAds && !isEmpty(homeCompanion) && <CompanionServerAd ad={homeCompanion} adKey="home_companion" /> }

            <ArticlesAndReviews posts={articlesAndReviews} />
            <PopularBoats posts={popularBoats} />
          </div>
        </div>
        { useServerAds && !isEmpty(homeCompantionStick) && <CompanionServerAd ad={homeCompantionStick} adKey="home_companion-sticky-banner" /> }
      </AdProvider>
    </>
  );
};

export default MainContent;

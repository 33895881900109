import ServerAd from '../../../components/ServerAd';
import classNames from 'classnames';
import React from 'react';
import './styles.css';

const CompanionServerAd = ({ ad, adKey }) => {
  return (
    <section className={classNames('companion-ad', { 'sticky': adKey.includes('sticky') })} id={`companion-ad-${adKey}`} data-e2e={`companion-ad-${adKey}`}>
      <ServerAd ad={ad} />
    </section>
  );
};

export default CompanionServerAd;

import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { matchAdName } from '../../../utils/ads/adsUtils';
import ServerAd from '../../../components/ServerAd';
import { Ad } from '@dmm/react-common-components';
import { BrandHeaderImage, IntroSection } from '@dmm/lib-react-ui-components';
import { setOemBrandProductImpression, setGenericEvent } from '../../../store/actions/dataLayer';
import './styles.css';
import { RenderHTML } from '@dmm/react-common-components';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { getBreakpoint, isSSR } from '../../../utils/commonHelper';
import { useDispatch } from 'react-redux';

const adParams = {
  adId: 'div-gpt-ad-box-1',
  classes: 'ad-720x300'
};

const mobileAdParam = {
  adId: 'div-gpt-ad-mobile-box-1',
  classes: 'ad-320x250'
};

const OemHeader = props => {
  const dispatch = useDispatch();
  const { oemDetails, onContact, params, serverAds } = props;
  const name = get(oemDetails, 'name', '');
  const headline = get(oemDetails, 'headline');
  const introduction = get(oemDetails, 'intro');
  const logoUrl = get(oemDetails, 'logoUrl');
  const oemHeroImageUrl = get(oemDetails, 'oemHeroImageUrl');
  const isOemDealer = get(oemDetails, 'oemDealerLocator', false);
  const renderAdParams = getBreakpoint() === 'mobile' ? mobileAdParam : adParams;
  const linkUrl = generateSearchPath({ make: normalizeString(name) });
  const buttonText = isOemDealer ? 'Find a Dealer' : 'Contact';
  const hasMobileBrandedSearch = getBreakpoint() === 'mobile' && matchAdName(serverAds,  'branded-search_mobile-box-1');
  const hasBrandedSearch = getBreakpoint() !== 'mobile' && matchAdName(serverAds, 'branded-search_box-1');
  const onClick = () => {
    if (isOemDealer) {
      setGenericEvent('soft lead', 'find a dealer', 'page navbar');
    }
    if (typeof onContact === 'function') {
      onContact(isOemDealer ? 'locator' : 'oem');
    }
  };

  useEffect(() => {
    dispatch(setOemBrandProductImpression(oemDetails.brandId, 'brand banner', 'brand banner', 1));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div id="oemSRP">
      {(logoUrl || oemHeroImageUrl) &&
      <BrandHeaderImage
        img={oemHeroImageUrl}
        logo={logoUrl}
        alt={name}
      />}
    </div>
    <div id="oemHeader" data-e2e="brands-oem-header">
      <div className="oem-intro-container" >
        <IntroSection
          brand={name} title={headline} linkUrl={linkUrl}
          buttonOnClick={onClick}
          buttonText={buttonText}
        >
          <div className="introduction-content">
            <RenderHTML html={introduction} />
          </div>
        </IntroSection>
        <div className="ad-container">
          {!isSSR() ? (params.useServerAds ?
            (hasMobileBrandedSearch || hasBrandedSearch) && <ServerAd
              ad={matchAdName(serverAds, getBreakpoint() === 'mobile' ? 'branded-search_mobile-box-1' : 'branded-search_box-1')}
              adName={getBreakpoint() === 'mobile' ? 'branded-search_mobile-box-1' : 'branded-search_box-1'}
              className="oem-header server-ad"
              wrapperExtra={renderAdParams}
            />
            :
            <Ad
              {...renderAdParams}
            />) : null}
        </div>
      </div>
    </div>
  </>;
};

OemHeader.propTypes = {
  oemDetails: PropTypes.shape({
    headline: PropTypes.string,
    introduction: PropTypes.string,
    logoUrl: PropTypes.string,
    oemHeroImageUrl: PropTypes.string,
    name: PropTypes.string,
    isOemDealer: PropTypes.bool
  }),
  onContact: PropTypes.any,
  serverAds: PropTypes.array,
  useServerAds: PropTypes.bool,
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setOemBrandProductImpression,
    setGenericEvent
  }, dispatch)
)(OemHeader);

import React from 'react';
import './styles.css';
import { Listing } from '@dmm/lib-react-ui-components';


/**
 * Content is temporary
 * @param listing: any
 */
const BoatType = ({listing, onClickCardHandler, typeTitle}) => {
  if (!listing) {
    return null;
  }

  const boatTypeCardProps = {
    name: listing.title,
    image: `${process.env.REACT_APP_HOST}/static/boat-types/${listing.id}.jpg`,
    imageAlt: listing.title,
    classNames: {
      card: true
    },
    description: listing.description,
    card: {
      onClick: () => { onClickCardHandler(listing.id, listing.title, typeTitle); },
      link: 'View More'
    },
    onClick: () => { onClickCardHandler(listing.id, listing.title, typeTitle); }
  };
  /* istanbul ignore next */
  if (['power-pontoon','power-jon','power-center'].includes(listing.id)) {
    const exceptionalClasses = {
      'power-pontoon': 'pontoon',
      'power-jon': 'jon',
      'power-center': 'center-console'
    };
    const boatTypeCardProps = {
      name: listing.title,
      image: `${process.env.REACT_APP_HOST}/static/boat-types/${listing.id}.jpg`,
      imageAlt: listing.title,
      classNames: {
        card: true
      },
      description: listing.description,
      card: {
        onClick: () => { window.location.href = `/boat-types/${exceptionalClasses[listing.id]}-boats/`; },
        link: 'View More'
      },
      onClick: () => { window.location.href = `/boat-types/${exceptionalClasses[listing.id]}-boats/`; }
    };
    return (
      <div key={listing.id} id={listing.id} data-e2e={listing.id} className="boat-type-card-container">
        <Listing key={listing.id} {...boatTypeCardProps} isLink={true} linkUrl={`/boat-types/${exceptionalClasses[listing.id]}-boats/`}></Listing>
      </div>
    );
  }
  return (
    <div key={listing.id} id={listing.id} data-e2e={listing.id} className="boat-type-card-container">
      <Listing key={listing.id} {...boatTypeCardProps} ></Listing>
    </div>
  );
};

export default BoatType;
